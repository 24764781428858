import {
  ITenantUser,
  ITenantUserUpdate,
  IPasswordUser,
  ICreateTenantRequest,
  ITenantParameters,
  ITenantUpdateStatusRequest,
  ITenantPartnerParameters,
  ITenantUpdateInvitationStatusRequest,
} from "@/models/Tenant";
import { ITenantUserParameters } from "@/models/TenantUser";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import { getEnv } from "@/utils/storage";

const TENANT = "Tenants";
const env = getEnv();
export class TenantUserAPI extends HttpClient {
  constructor(token?: string) {
    super(env?.VUE_APP_DOMAIN_API_SERVER, token);
  }

  public getTenant = async (TenantId: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/${TenantId}`)
      .catch(catchAxiosError);

    return response;
  };

  public createUser = async (data: ITenantUser): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/users`, data)
      .catch(catchAxiosError);

    return response;
  };

  public createTenant = async (
    data: ICreateTenantRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/create-tenant`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updateUser = async (
    UserId: string,
    data: ITenantUserUpdate
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${TENANT}/users/${UserId}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updatePassword = async (
    UserId: string,
    data: IPasswordUser
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/users/${UserId}/passwords`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getListTenantUser = async (
    params: ITenantUserParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/users`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getTenantUser = async (
    UserId: string | number
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/users/${UserId}`)
      .catch(catchAxiosError);

    return response;
  };

  public sendActivationEmail = async (UserId: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/users/${UserId}/send-activation-email`)
      .catch(catchAxiosError);
    return response;
  };

  public getTenantProfile = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/profile`)
      .catch(catchAxiosError);
    return response;
  };

  public getListTenant = async (
    params?: ITenantParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(TENANT, { params })
      .catch(catchAxiosError);

    return response;
  };

  public updateTenantStatus = async (
    data: ITenantUpdateStatusRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/${data.TenantId}/approval`, {
        Action: data.Action,
        RemarkContent: data.RemarkContent ?? "",
      })
      .catch(catchAxiosError);

    return response;
  };

  public getListTenantPartner = async (params?: ITenantPartnerParameters): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/partners`, { params })
      .catch(catchAxiosError);

    return response;
  }

  public updateTenantInvitationStatus = async (data: ITenantUpdateInvitationStatusRequest): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${TENANT}/invitation/${data.InvitationId}`, {
        Action: data.Action
      })
      .catch(catchAxiosError);

      return response;
  };

  public getAccountRegistrationPrefillData = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/prefill-data`)
      .catch(catchAxiosError);

    return response;
  };
}
